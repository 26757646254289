import BezierEasing from 'bezier-easing';
import './js/header';


const footer = document.querySelector('.js-footer');
const footerPlaceholder = document.querySelector('.js-footer-placeholder');
footerPlaceholder.style.height = footer.getBoundingClientRect().height + 'px';

window.addEventListener('resize', () => {
    footerPlaceholder.style.height = footer.getBoundingClientRect().height + 'px';
});

document.addEventListener('DOMContentLoaded', function() {
    const videos = document.querySelectorAll('.js-background-video');
    if (videos.length) {
        videos.forEach((video) => {
            const playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise.catch((error) => {
                    video.controls = false;
                    video.autoplay = true;
                    video.muted = true;
                    video.play();
                });
            }
        });
    } 
});

const scrollStart = document.querySelector('.js-scroll-start');
const scrollEnd = document.querySelector('.js-scroll-end');
const scrollableCols = document.querySelectorAll('.js-scrollable-col');

const colsEasing = [
    BezierEasing(.17,.01,1,.99), // col 1 
    BezierEasing(.6,.79,1,.99), // col 2
    BezierEasing(.33,.78,1,.99), // col 3
];

if (scrollableCols.length) {
    window.addEventListener('scroll', (e) => {
        const { top } = scrollStart.getBoundingClientRect();
        const end = scrollEnd.offsetTop - scrollStart.offsetTop;
        let res = Math.min(1, -top / end);
    
        if (top > 0) {
            res = 0;
        }
    
        if (Math.abs(top) > end) {
            res = 1;
        }
    
        scrollableCols.forEach((col, i) => {
            col.style.transform = `translateY(-${colsEasing[i](res) * 100}%)`;
        });
    });
}