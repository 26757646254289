const header = document.querySelector('.js-header');
const burger = document.querySelector('.js-burger');
const mobileMenu = document.querySelector('.js-mobile-menu');
const searchBtn = document.querySelector('.js-search-btn');
const headerSearch = document.querySelector('.js-header-search');
const mobileAuthTogglers = document.querySelectorAll('.js-mobile-auth-toggle');
const mobileAuth = document.querySelector('.js-mobile-auth');

burger.addEventListener('click', (e) => {
    burger.classList.toggle('open');

    if (burger.classList.contains('open')) {
        mobileMenu.classList.add('open');
        header.classList.add('fixed');
    } else {
        mobileMenu.classList.remove('open');
        header.classList.remove('fixed');
    }
});

searchBtn.addEventListener('click', (e) => {
    headerSearch.classList.add('open');
});

window.addEventListener('click', (e) => {
    if (e.target != searchBtn) {
        if (headerSearch.classList.contains('open')) {
            headerSearch.classList.remove('open');
        }
    }
});

headerSearch.addEventListener('click', (e) => {
    e.stopPropagation();
});

mobileAuthTogglers.forEach((btn) => {
    btn.addEventListener('click', (e) => {
        mobileAuth.classList.toggle('open');
    });
});